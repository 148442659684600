<template>
    <CHeader position="sticky">
        <CContainer fluid>
            <CHeaderToggler @click="toggleSidebar">
                <CIcon icon="cil-menu" size="lg" />
            </CHeaderToggler>
            <CHeaderBrand class="mx-auto d-lg-none">
                SatuSehat DokterPro
            </CHeaderBrand>
            <CHeaderNav>
                <CNavItem>
                    <CNavLink
                        href="javascript: void(0);"
                        class="text-dark font-weight-bold"
                    >
                    </CNavLink>
                </CNavItem>
                <AppHeaderDropdownAccnt />
            </CHeaderNav>
        </CContainer>
        <CHeaderDivider />
        <CContainer fluid>
            <AppBreadcrumb />
            <!-- <CButton
                @click="onOptimize"
                color="dark"
                variant="outline"
                size="sm"
                class="mx-1"
            >
                optimize
            </CButton> -->
        </CContainer>
    </CHeader>
</template>

<script>
import { useSidebarStore } from '@/store'
import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import { mapActions } from 'pinia'
import { http } from '@/config'
import { useSpinnerStore } from '@/store/spinner'
import { useToastStore } from '@/store/toast'

export default {
    name: 'AppHeader',
    components: {
        AppBreadcrumb,
        AppHeaderDropdownAccnt,
    },
    data() {
        return {
            darkMode: localStorage.getItem('darkMode'),
        }
    },
    methods: {
        ...mapActions(useSidebarStore, ['toggleSidebar']),
        onOptimize() {
            useSpinnerStore().loading(true)

            http.get('/clear')
                .then((response) => {
                    useToastStore().showToast({
                        show: true,
                        classType: 'bg-success',
                        title: 'Sukses',
                        msg: 'Sukses Optimize Aplikasi',
                    })
                })
                .catch((err) => {
                    useToastStore().showToast({
                        show: true,
                        classType: 'bg-danger',
                        title: 'Gagal',
                        msg: 'Gagal Optimize Aplikasi',
                    })
                })
                .finally(() => {
                    useSpinnerStore().loading(false)
                })
        },
    },
}
</script>
