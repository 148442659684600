import { createRouter, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'
import { useAuthStore } from '@/store/auth'
import { objectIsEmpty } from '@/utils'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: DefaultLayout,
        redirect: '/dashboard',
        children: [
            {
                path: 'dashboard/',
                name: 'Dashboard',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'
                    ),
            },
            {
                path: 'use-case2/bundle1/',
                name: 'UseCase2Bundle1',
                meta: {
                    role: ['Admin'],
                },
                component: () => import('@/views/usecase2/bundle/Index.vue'),
            },
            {
                path: 'rawat-jalan/:tab?',
                name: 'RawatJalan',
                meta: {
                    role: ['Admin'],
                },
                component: () => import('@/views/usecase2/Index.vue'),
            },
            {
                path: 'search/:resource?',
                name: 'Search',
                meta: {
                    role: ['Admin'],
                },
                component: () => import('@/views/search/Index.vue'),
            },
            {
                path: 'profil/',
                name: 'Profile',
                meta: {
                    role: ['Admin'],
                },
                component: () => import('@/views/profile/Index.vue'),
            },
            {
                path: 'history/',
                name: 'History',
                meta: {
                    role: ['Admin'],
                },
                component: () => import('@/views/history/Index.vue'),
                children: [
                    {
                        path: 'rawat-jalan/:resource?',
                        name: 'HistoryRaJal',
                        meta: {
                            role: ['Admin'],
                        },
                        component: () => import('@/views/history/Index.vue'),
                    },
                ],
            },
            {
                path: 'kyc/',
                name: 'Kyc',
                meta: {
                    role: ['Admin'],
                },
                component: () => import('@/views/kyc/Index.vue'),
                children: [
                    {
                        path: '',
                        name: 'LKyc',
                        meta: {
                            role: ['Admin'],
                        },
                        component: () => import('@/views/kyc/Index.vue'),
                    },
                ],
            },
        ],
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/auth/Login'),
        beforeEnter: (to, from) => {
            // reject the navigation
            const auth = useAuthStore()
            if (!objectIsEmpty(auth.getLoggedIn())) {
                return '/'
            }
        },
    },
    {
        path: '/login/by-token/:token?',
        name: 'authSatusehat',
        component: () => import('@/views/auth/LoginByToken.vue'),
        beforeEnter: (to, from) => {
            // reject the navigation
            const auth = useAuthStore()
            if (!objectIsEmpty(auth.getLoggedIn())) {
                return '/'
            }
        },
    },
]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        // always scroll to top
        return { top: 0 }
    },
})

router.beforeEach(async (to) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['Login', 'authSatusehat']
    const authRequired = !publicPages.includes(to.name)
    const auth = useAuthStore()
    if (authRequired && objectIsEmpty(auth.getLoggedIn())) {
        auth.returnUrl = to.fullPath

        return '/login'
    }
})

export default router
