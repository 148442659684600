export default [
    {
        component: 'CNavItem',
        name: 'Dashboard',
        to: '/dashboard',
        roles: ['PLATINUM', 'GOLD', 'SILVER'],
        icon: 'cil-speedometer',
    },
    {
        component: 'CNavTitle',
        name: 'Menu',
    },
    {
        component: 'CNavItem',
        name: 'Kirim Data',
        to: '/rawat-jalan/bundle',
        roles: ['PLATINUM', 'GOLD', 'SILVER'],
        icon: 'cil-star',
    },
    // {
    //     component: 'CNavItem',
    //     name: 'Search',
    //     to: '/search/patient',
    //     roles: ['PLATINUM', 'GOLD', 'SILVER'],
    //     icon: 'cil-cursor',
    // },
    // {
    //     component: 'CNavItem',
    //     name: 'KYC',
    //     to: '/kyc',
    //     roles: ['PLATINUM', 'GOLD', 'SILVER'],
    //     icon: 'cil-star',
    // },
    {
        component: 'CNavItem',
        name: 'History',
        to: '/history/rawat-jalan/Bundle',
        roles: ['PLATINUM', 'GOLD', 'SILVER'],
        icon: 'cilGrid',
    },
]
