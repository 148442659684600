import { defineStore } from 'pinia'
import axios from 'axios'
import Cookies from 'js-cookie'
import { url } from '@/config/http'

// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const useAuthStore = defineStore('auth', {
    state: () => ({
        cookies: Cookies.get('user'),
        user: {},
        token: '',
        loggedIn: '',
    }),
    actions: {
        async login(request) {
            // await axios.get(url + 'http://localhost/simpeg/sanctum/csrf-cookie')
            const user = await axios.post(url + '/login/', {
                pengguna: request.pengguna,
                password: request.password,
                device_name: 'web',
            })

            return user
        },

        async logout() {
            this.user = null
            this.token = null

            let myToken = this.getToken()

            await axios.delete(url + '/logout-by-token', {
                headers: {
                    Authorization: 'Bearer ' + myToken,
                    Accept: 'application/json',
                },
                withCredentials: true,
            })
            Cookies.remove('user')

            return true
        },

        getLoggedIn() {
            this.loggedIn =
                this.cookies === undefined
                    ? undefined
                    : JSON.parse(this.cookies).typeLogin

            return this.loggedIn
        },

        getToken() {
            this.token =
                this.cookies == undefined ? '' : JSON.parse(this.cookies)?.token

            return this.token
        },

        getUser() {
            this.user =
                this.cookies == undefined ? {} : JSON.parse(this.cookies)?.data

            return this.user
        },

        setAuthStore() {
            this.getLoggedIn()
            this.getToken()
            this.getUser()
        },
    },
})
