import { useAuthStore } from '@/store/auth'
import { useSpinnerStore } from '@/store/spinner'
import axios from 'axios'
import Cookies from 'js-cookie'

// const url = 'http://localhost/dokterpro-ss/backend/api' //WEB APACHE
const url = 'https://satusehat.dokterpro.com/backend/api' //WEB APACHE

const token =
    Cookies.get('user') != undefined
        ? JSON.parse(Cookies.get('user')).token
        : ''

const http = axios.create({
    baseURL: url,
    headers: {
        Authorization: 'Bearer ' + token,
        Accept: 'application/json',
    },
    withCredentials: true,
})

http.interceptors.request.use(
    function (x) {
        // to avoid overwriting if another interceptor
        // already defined the same object (meta)
        useSpinnerStore().loading(true)

        return x
    },
    (err) => {
        useSpinnerStore().loading(false)

        return Promise.reject(err)
    },
)

http.interceptors.response.use(
    (x) => {
        useSpinnerStore().loading(false)
        return x
    },
    (error) => {
        useSpinnerStore().loading(false)

        /**
         * SATUSEHAT FAIL
         */
        console.log(error)
        if (
            error.response.status === 401 &&
            error.response.data?.code === 'SSFAIL'
        ) {
            error.response.data.errors = error.response.data.message
        }

        if (
            error.response.status === 401 &&
            error.response.statusText === 'Unauthorized'
        ) {
            error.response.data.errors = 'SESI HABIS LOGIN KEMBALI'
            useAuthStore().logout()
            // window.location.reload()
        }

        return Promise.reject(error)
    },
)

export { http, url }
