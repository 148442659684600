<template>
    <CDropdown variant="nav-item">
        <CDropdownToggle
            placement="bottom-end"
            class="py-0"
            :caret="false"
            href="javascript: void(0)"
        >
            {{ user.nama.toUpperCase() }}
        </CDropdownToggle>
        <CDropdownMenu class="pt-0">
            <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
                Pengaturan
            </CDropdownHeader>
            <CDropdownItem
                @click="() => $router.push({ name: 'Profile' })"
                style="cursor: pointer"
            >
                <CIcon :icon="cilUser" /> Profil
            </CDropdownItem>
            <CDropdownItem @click="onLogout" style="cursor: pointer">
                <CIcon icon="cil-lock-locked" /> Logout
            </CDropdownItem>
        </CDropdownMenu>
    </CDropdown>
</template>

<script>
import { useAuthStore } from '@/store/auth'
import { useRouter } from 'vue-router'
import { cilUser } from '@coreui/icons'

export default {
    name: 'AppHeaderDropdownAccnt',
    setup() {
        const router = useRouter()

        const user = useAuthStore().getUser()

        const onLogout = async () => {
            const logoutAct = await useAuthStore().logout()

            if (logoutAct) {
                router.go('/login')
            }
        }

        return {
            itemsCount: 42,
            onLogout: onLogout,
            user,
            cilUser,
        }
    },
}
</script>
