<template>
    <div>
        <router-view v-slot="{ Component }">
            <transition name="fade-x" mode="in-out">
                <component :is="Component" />
            </transition>
        </router-view>
    </div>
</template>
<script></script>
<style lang="scss">
// Import Main styles for this application
@import 'vue-select/dist/vue-select.css';
@import 'styles/style';
</style>
