<template>
    <CFooter class="bg-white">
        <!-- <div>
            <a href="https://coreui.io" target="_blank">SatuSehat Client App</a>
            <span class="ms-1"
                >&copy; {{ new Date().getFullYear() }} RS GRAHA SEHAT
                KRAKSAAN</span
            >
        </div>
        <div class="ms-auto">
            <span class="me-1" target="_blank">Develop by</span>
            <a href="#">Salman</a>
        </div> -->
    </CFooter>
</template>

<script>
export default {
    name: 'AppFooter',
}
</script>
