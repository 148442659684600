<style>
.scroll {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
}
</style>
<template>
  <div>
    <Transition name="slide">
      <Spinner v-if="isLoading"></Spinner>
    </Transition>
    <div class="c-dark-theme">
      <AppSidebar />
      <div class="wrapper d-flex flex-column min-vh-100 bg-cs">
        <AppHeader />
        <div class="body flex-grow-1 position-relative">
          <CContainer
            lg
            class="my-2"
          >
            <router-view v-slot="{ Component, route }">
              <Transition
                name="fade-x"
                mode="out-in"
              >
                <div :key="route.name">
                  <component :is="Component"></component>
                </div>
              </Transition>
            </router-view>
          </CContainer>
        </div>
        <AppFooter />
        <Transition
          name="fade-x"
          mode="out-in"
        >
          <Toast />
        </Transition>
      </div>
    </div>
    <div>
      <CButton
        class="scroll"
        color="primary"
        variant="outline"
        @click="toTop"
      >
        <CIcon :icon="cilArrowThickTop" />
      </CButton>
    </div>
  </div>
</template>
<script>
import { CContainer } from '@coreui/vue'
import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppSidebar from '@/components/AppSidebar.vue'
import { CSpinner } from '@coreui/vue'
import Spinner from '@/components/Spinner.vue'
import { useSpinnerStore } from '@/store/spinner'
import { mapState } from 'pinia'
import Toast from '@/components/Toast.vue'
import CIcon from '@coreui/icons-vue'
import { cilArrowThickTop } from '@coreui/icons'

export default {
    name: 'DefaultLayout',
    components: {
        AppFooter,
        AppHeader,
        AppSidebar,
        CContainer,
        CSpinner,
        Spinner,
        Toast,
        CIcon,
    },
    data() {
        return {
            isVisible: false,
            cilArrowThickTop,
        }
    },
    computed: {
        ...mapState(useSpinnerStore, ['isLoading']),
    },
    methods: {
        toTop() {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            })
        },
        toBottom() {
            window.scrollTo({
                top: document.body.scrollHeight,
                behavior: 'smooth',
            })
        },
    },
}
</script>
