import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import 'bootstrap'
import Spinner from '@/components/Spinner.vue'
import VueGoodTablePlugin from 'vue-good-table-next'
import { createPinia } from 'pinia'
import VueSelect from 'vue-select'
import Vue3Geolocation from 'vue3-geolocation'
import './registerServiceWorker'

// import the styles
import 'vue-good-table-next/dist/vue-good-table-next.css'

const pinia = createPinia()
const app = createApp(App)

// let darkMode = localStorage.getItem('darkMode')

// if (darkMode) {
//     document.body.classList.add('dark-mode')
// }

app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(VueGoodTablePlugin)
app.use(pinia)
app.use(Vue3Geolocation)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('Spinner', Spinner) // spinner
app.component('v-select', VueSelect)

app.mount('#app')
