<template>
    <CSidebar
        position="fixed"
        :unfoldable="sidebarUnfoldable"
        :visible="sidebarVisible"
        @visible-change="updateSidebarVisible"
    >
        <CSidebarBrand>
            <div class="p-2 text-center">
                <strong style="font-size: 14px">{{ nama }}</strong>
            </div>
        </CSidebarBrand>
        <AppSidebarNav />
        <CSidebarToggler class="d-none d-lg-flex" @click="toggleUnfoldable" />
    </CSidebar>
</template>

<script>
import { useSidebarStore } from '@/store/index.js'
import { AppSidebarNav } from './AppSidebarNav'
import { logoNegative } from '@/assets/brand/logo-negative'
import { sygnet } from '@/assets/brand/sygnet'
import { mapActions, mapState } from 'pinia'
import { useAuthStore } from '@/store/auth'
export default {
    name: 'AppSidebar',
    components: {
        AppSidebarNav,
    },
    data() {
        return {
            logoNegative,
            sygnet,
            nama: '',
        }
    },
    computed: {
        ...mapState(useSidebarStore, ['sidebarUnfoldable', 'sidebarVisible']),
    },
    created() {
        this.nama = useAuthStore().getUser().nama
    },
    methods: {
        ...mapActions(useSidebarStore, [
            'updateSidebarVisible',
            'toggleUnfoldable',
        ]),
    },
}
</script>
